export const DEFAULT_VIDEO_CONSTRAINTS = {
  aspectRatio: 1.142_857_143,
};

// TODO: T207128 - Implement FAB on a separate component and use it here
export enum CameraInputTriggerType {
  DEFAULT = 'default',
  FLOATING_ACTION_BUTTON = 'floating-action-button',
}

export enum CameraImageType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
}
